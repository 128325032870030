import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originPush = Router.prototype.push
const originReplace = Router.prototype.replace
// 给原型对象上的push指定新函数函数
Router.prototype.push = function (location, onComplete, onAbort) {
	// 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
	if (onComplete === undefined && onAbort === undefined) {
		return originPush
			.call(this, location, onComplete, onAbort)
			.catch(() => {})
	} else {
		// 如果有指定任意回调函数, 通过call调用源push函数处理
		originPush.call(this, location, onComplete, onAbort)
	}
}
// replace同理处理
Router.prototype.replace = function (location, onComplete, onAbort) {
	if (onComplete === undefined && onAbort === undefined) {
		return originReplace
			.call(this, location, onComplete, onAbort)
			.catch(() => {})
	} else {
		originReplace.call(this, location, onComplete, onAbort)
	}
}

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: '/login',
		},
		{
			path: '/login',
			component: () => import('../views/Login.vue'),
			meta: {
				title: '登录',
			},
		},

		{
			path: '/',
			component: () => import('../layout/main.vue'),
			meta: {
				title: '整体页面布局',
			},
			children: [
				{
					path: '/index',
					component: () => import('../views/index/index.vue'),
					meta: {
						title: '首页',
						keepAlive: true, //存活组件,让websoket进行长连接
						auth: true,
					},
				},
				{
					path: '/userInfo',
					component: () => import('../views/userInfo/index.vue'),
					meta: {
						title: '个人中心',
						auth: true,
					},
				},
				{
					path: '/liaison',
					component: () => import('../views/liaison/index.vue'),
					meta: {
						title: '联系人',
						auth: true,
					},
				},
				{
					path: '/template',
					component: () => import('../views/template/index.vue'),
					meta: {
						title: '模板',
						auth: true,
					},
				},
				{
					path: '/record',
					component: () => import('../views/record/index.vue'),
					meta: {
						title: '发送记录',
						auth: true,
					},
				},
				{
					path: '/group',
					component: () => import('../views/group/index.vue'),
					meta: {
						title: '群发',
						auth: true,
					},
				},

				{
					path: '/myInfo',
					component: () => import('../views/myInfo/index.vue'),
					meta: {
						title: '我的',
						auth: true,
					},
				},

				{
					path: '/resource',
					component: () => import('../views/resource/index.vue'),
					meta: {
						title: '资源',
						auth: true,
					},
				},
				{
					path: '/register',
					component: () => import('../views/register/register.vue'),
					meta: {
						title: '开户',
					},
				},
			],
		},
	],
})

router.beforeEach((to, from, next) => {
	// to要跳转到的路径
	// from从哪个路径来
	// next往下执行的回调
	// 在localStorage中获取token
	let token = localStorage.getItem('BIGDOG_USER')
	// 判断该页面是否需要登录
	if (to.meta.auth) {
		// 如果token存在直接跳转
		if (token) {
			next()
		} else {
			// 否则跳转到login登录页面
			next({
				path: '/login',
				// 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
				query: {
					redirect: to.fullPath,
				},
			})
		}
	} else {
		// 如果不需要登录，则直接跳转到对应页面
		next()
	}
})

export default router
