//引入Vue核心库
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)

const state = {
	// 临时变量
	TMP: {
		do_close_page: false, // 用来所有自定义标签页上的关闭按钮控制标签页的关闭，在App组件中添加Watch
		after_login_router: '/home', // 登录后跳转路由path
		request_count: 0, // 当前正在请求数
		loadingBar: false,
		session_id: '',
	},
	// websocket实例
	wsObj: null,
	// ws连接地址
	wsUrl: null,
	// 是否执行重连 true/不执行 ； false/执行
	lockReconnect: false,
	// 重连定时器
	// wsCreateHandler: null,
	timeout: 60000,
	timeoutObj: null,
	serverTimeoutObj: null, //
	// 连接成功，执行回调函数
	messageCallback: null,
	// 连接失败，执行回调函数
	errorCallback: null,
	messageResults: {},
	// // 发送给后台的数据
	// sendDatas: {},
	// // 每条数据组成的对象
	// messageList: [],
}

const mutations = {
	// 用来更新 TMP 里变量的方法
	SET_TMP_VALUE(state, item) {
		state.TMP[item.name] = item.value
	},
	// 改变websoket示例
	INIT_WSOBJ(state, data) {
		state.wsObj = data
	},
	// 是否执行重连
	LOCK_RE_CONNECT(state, data) {
		state.lockReconnect = data
	},
	// 重连定时器
	WS_CREATE_HANDLER(state, data) {
		state.wsCreateHandler = data
	},
	// 接收信息发送出去
	SET_MESSAGE_RESULTS(state, data) {
		state.messageResults = data
	},
	// 改变发给后台的数据和组成的对象
}
const actions = {
	// 初始化websoket
	initWebSocket({ state, commit, dispatch }, userId) {
		// const realUrl = 'ws://192.168.2.234:10002/wss/' + userId + '?req=ok'
		const realUrl = 'wss://ws.yunzhangshi.cn/wss/' + userId + '?req=ok'
		try {
			if (state.wsObj) {
				dispatch('close')
			}
			const wsObj = new WebSocket(realUrl)
			commit('INIT_WSOBJ', wsObj)
			// 打开websoket
			state.wsObj.onopen = () => {
				console.log(wsObj, 'wsbj')
				console.log('连接已开启...')
				if (wsObj.readyState === wsObj.OPEN) {
					// 发给后端的数据需要字符串化
					console.log('vuex发送标识1')
					dispatch('webSocketSend', '1')
				}
				if (wsObj.readyState === wsObj.CLOSED) {
					console.log('readyState=3, ws连接异常，开始重连')
					dispatch('reConnect')
				}
				dispatch('start')
			}
			// 接收到websoket的事件
			state.wsObj.onmessage = (e) => {
				// console.log(e.data, 'e.data')
				let results = JSON.parse(e.data)
				commit('SET_MESSAGE_RESULTS', results)
			}
			// 关闭websoket
			state.wsObj.onclose = () => {
				console.log('vuex连接已关闭...')
			}
			// websoket失败
			state.wsObj.onerror = (e) => {
				console.log(e)
			}
			console.log(state.wsObj, 111)
			commit('INIT_WSOBJ', wsObj)
		} catch {
			console.log('连接异常，开始重连')
			dispatch('reConnect')
		}

		console.log(state.wsObj, 'vuex里面的wsboj')
	},

	// 打开定时器
	start({ state, commit, dispatch }) {
		state.timeoutObj && clearTimeout(state.timeoutObj)
		state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj)
		// 15s之内如果没有收到后台的消息，则认为是连接断开了，需要重连
		state.timeoutObj = setTimeout(() => {
			console.log('心跳检查，发送1到后台')
			try {
				dispatch('webSocketSend', '1')
			} catch (err) {
				console.log('发送1异常')
				dispatch('reConnect')
			}
			// console.log(
			// 	'内嵌定时器this.serverTimeoutObj: ',
			// 	state.serverTimeoutObj
			// )
			// 内嵌定时器
			// state.serverTimeoutObj = setTimeout(() => {
			// 	console.log('没有收到后台的数据，重新连接')
			// 	dispatch('reConnect')
			// }, 100)
		}, state.timeout)
	},
	// 关闭定时器
	close({ state, commit, dispatch }) {
		state.wsObj.close() // 关闭 websocket
		state.wsObj.onclose = function (e) {
			console.log(e) //监听关闭事件
			console.log('关闭')
		}
	},
	// 重启
	reset({ state, dispatch }) {
		clearTimeout(state.timeoutObj)
		clearTimeout(state.serverTimeoutObj)
		dispatch('start')
	},
	// 停止
	stop({ state, dispatch }) {
		clearTimeout(state.timeoutObj)
		clearTimeout(state.serverTimeoutObj)
	},

	// websoket发送文件
	webSocketSend({ state, commit, dispatch }, agentData) {
		state.wsObj.send(agentData)
		console.log('vuex里面的发送信息', agentData)
	},
	// 心跳包
	// heartCheck({ state, commit }) {
	// 	console.log(state, 'state')
	// 	const { wsObj } = state
	// 	const wsCreateHandler = setInterval(() => {
	// 		if (wsObj.readyState === 1) {
	// 			wsObj.send('PING')
	// 		}
	// 	}, 20000)
	// 	commit('WS_CREATE_HANDLER', wsCreateHandler)
	// },
	//重新链接
	reConnect({ state, commit, dispatch }) {
		if (state.lockReconnect) {
			return
		}
		if (state.wsObj) {
			dispatch('close')
		}
		// commit('INIT_WSOBJ', null)
		commit('LOCK_RE_CONNECT', true)
		state.wsCreateHandler && clearTimeout(state.wsCreateHandler)
		state.wsCreateHandler = setTimeout(() => {
			console.log('重连...')
			let userId = localStorage.getItem('userId')
			dispatch('initWebSocket', userId)
			commit('LOCK_RE_CONNECT', false)
			console.log('重连完成')
		}, 3000)
	},
}

const getters = {
	wsObj: (state) => state.wsObj,
	messageList: (state) => state.messageList,
}

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
})
