import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'default-passive-events'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'

import adaptive from '@/utils/tableHeight'
// 分页组件
import throatBtn from '@/components/throatBtn.vue'
import Pagination from '@/components/Pagination'
import SlideVerify from 'vue-monoplasty-slide-verify'
Vue.use(SlideVerify)
import waterfall from 'vue-waterfall2'
import previewImg from 'vue-preview-img'
import '../node_modules/vue-preview-img/lib/vue-preview-img.css'
import VueClipBoard from 'vue-clipboard2'
// 防抖节流
// import debounce from '@/utils/autils'

// import * as websocket from './utils/socket'
import moment from 'moment'
import './assets/fonts/iconfont.css'
moment.locale('zh-cn')

// 引入字体图标

Vue.filter('dateFmt', (input, formatString = 'YYYY-MM-DD HH:mm:ss') => {
	//es5函数参数设置默认值
	//const lastFormatString = formatString || ''
	// moment(input) 把时间字符串转成时间对象
	// format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串

	return moment(input).calendar()
	//  moment(input).format(formatString)
})

// Vue.prototype.$websocket = websocket
Vue.use(previewImg)
Vue.use(waterfall)
Vue.use(VueClipBoard)
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('throatBtn', throatBtn)

Vue.use(adaptive)
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
	router,
	render: (h) => h(App),
	store,
}).$mount('#app')
